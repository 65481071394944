
import { defineComponent } from "vue";
import SortingArrow from "@/components/general/sortingArrow.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "languagesDataTable",
  data() {
    return {
      sortParams: {
        sortedBy: "",
        isOrderAsc: false
      }
    };
  },
  components: {
    SortingArrow
  },
  props: {
    tableData: Object
  },
  methods: {
    changeSort(userSelectedSortValue) {
      if (userSelectedSortValue == this.sortParams.sortedBy) {
        this.sortParams.isOrderAsc = !this.sortParams.isOrderAsc;
      } else {
        this.sortParams.isOrderAsc = false;
      }
      this.sortParams.sortedBy = userSelectedSortValue;
      this.$emit("changeSort", this.sortParams);
    },
    editButtonClicked(entry) {
      this.$emit("editButtonClick", entry);
    },
    deleteButtonClicked(entry) {
      this.$emit("deleteButtonClick", entry);
    }
  },
  computed: {
    ...mapGetters("LanguagesModule", ["getSortData"])
  },
  mounted() {
    this.sortParams = this.getSortData;
  }
});
