
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import LanguagesDataTable from "@/components/system/Tables/LanguagesDataTable.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import LanguageModal from "@/components/system/Modals/LanguageModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";

export default defineComponent({
  name: "LanguagesOverview",
  components: {
    LanguagesDataTable,
    LoadingSpinner,
    Pagination,
    LanguageModal,
    DeleteModal
  },
  data() {
    return {
      pagination: {},
      modalSetup: {
        action: "",
        title: ""
      },
      currentlySelectedLanguage: {
        id: null,
        name: null
      },
      isThereAModalActive: false
    };
  },
  props: {
    widgetClasses: String
  },
  methods: {
    ...mapActions("LanguagesModule", [
      "fetchData",
      "setCurrentPage",
      "resetState",
      "setSortParams",
      "update",
      "createNew",
      "destroyData"
    ]),
    changeCurrentPage(value) {
      this.setCurrentPage(value);
      this.fetchData();
    },
    updateTableSort(sortParams) {
      this.setSortParams(sortParams);
      this.fetchData();
    },
    openEditModal(language) {
      this.modalSetup.title = "Edit Language";
      this.modalSetup.action = "edit";
      this.currentlySelectedLanguage = language;
    },
    openAddNewModal() {
      this.resetLanguageData();
      this.modalSetup.title = "";
      this.$nextTick(() => this.modalSetup.title = "Add new Language");
      this.modalSetup.action = "addnew";
      this.isThereAModalActive = true;
    },
    openDeleteModal(entry) {
      this.currentlySelectedLanguage = entry;
      this.isThereAModalActive = true;
    },
    deleteSelectedLanguage() {
      this.destroyData(this.currentlySelectedLanguage.id);
      this.isThereAModalActive = false;
      this.currentlySelectedLanguage.id = null;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.action == "edit") {
        this.update({
          values: dataFromModal.payload,
          id: this.currentlySelectedLanguage.id
        });
      } else {
        this.createNew(dataFromModal.payload);
      }
    },
    cancelModal() {
      this.isThereAModalActive = false;
    },

    resetLanguageData() {
      this.currentlySelectedLanguage = {
        id: null,
        name: null
      };
    }
  },
  computed: {
    ...mapGetters("LanguagesModule", [
      "getData",
      "getPaginationData",
      "loading"
    ])
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.pagination = this.getPaginationData;
  },
  unmounted() {
    this.resetState();
  }
});
